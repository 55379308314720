define(['app', 'elementViewed', 'viewport', 'uniqueIDHelper'], function(app, elementViewed, viewPort, uniqueIDHelper) {



  var ProductRecommendations = function() {
    var self = this;
    self.app = app;
    self.elementViewed = elementViewed;
    self.viewPort = viewPort;

    var _config = {
      dataRecommendationProductSelector: '[data-recommendation-product=product]',
      dataRecommendationBuyNowSelector: '[data-recommendation-buy-now=button]',
      label: {
        recommendationsRailsComponent: 'Recommendations rails component',
        recommendationsProductClickedPosition: 'Recommendations product clicked position ',
        recommendationsProductBuyPosition: 'Recommendations product buy position ',
        clicked: 'Clicked',
        viewed: 'Viewed'
      },
      event: {
        click: 'click'
      }
    };

    var _init = function(element) {
      self.element = element;
      var i;
      var l;

      // if this container is in-view on page load, just fire the event
      if (self.viewPort.isElementVisible(self.element)) {
        self.triggerTrackingEvent(self.config.label.viewed,
          self.config.label.recommendationsRailsComponent);
      } else {
        //otherwise register the view handler to fire it
        self.elementViewed(self.element, self.trackComponentViewed);
      }

      var recommendedProducts = self.element.querySelectorAll(
        self.config.dataRecommendationProductSelector);

      var attachClickListener = function(target, i, tracking) {
        target[i].addEventListener(self.config.event.click, function() {
          tracking(i + 1);
        });
      };

      for (i = 0, l = recommendedProducts.length; i < l; i++) {
        attachClickListener(recommendedProducts, i, self.trackProductClicked);
      }

      var buyNowButtons = self.element.querySelectorAll(
        self.config.dataRecommendationBuyNowSelector);

      for (i = 0, l = buyNowButtons.length; i < l; i++) {
        attachClickListener(buyNowButtons, i, self.trackProductBuyNow);
      }

      uniqueIDHelper.ensureAriaLabelledByIdsAreUnique(self.element);
    };

    var _trackComponentViewed = function() {
      self.triggerTrackingEvent(self.config.label.viewed,
        self.config.label.recommendationsRailsComponent);
    };

    var _trackProductClicked = function(listPosition) {
      self.triggerTrackingEvent(self.config.label.clicked,
        self.config.label.recommendationsProductClickedPosition
        + listPosition);
    };

    var _trackProductBuyNow = function(listPosition) {
      self.triggerTrackingEvent(self.config.label.clicked,
        self.config.label.recommendationsProductBuyPosition + listPosition);
    };

    var _triggerTrackingEvent = function(action, label) {
      app.publish('tracking/record', 'Product | Recommendations', action, label);
    };

    self.init = _init;
    self.config = _config;
    self.triggerTrackingEvent = _triggerTrackingEvent;
    self.trackComponentViewed = _trackComponentViewed;
    self.trackProductClicked = _trackProductClicked;
    self.trackProductBuyNow = _trackProductBuyNow;
  };

  return ProductRecommendations;
});
